<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="学院名称：" prop="schoolName">
        <el-input v-model="form.schoolName" placeholder="请输入活动标题"></el-input>
      </el-form-item>
      <el-form-item label="专业类型：" prop="type">
        <el-select v-model="form.type" placeholder="请选择专业类型" @change="typeChange">
          <el-option key="1" label="文科" value="文科"></el-option>
          <el-option key="2" label="理科" value="理科"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专业名称：" prop="majorName">
        <el-input v-model="form.majorName" placeholder="请输入专业名称"></el-input>
      </el-form-item>
      <el-form-item label="专业介绍：" prop="introduction">
        <el-input type="textarea" :rows="4" v-model.number="form.introduction" placeholder="请输入专业介绍"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'studySignModal',
  props: ['oldForm'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      rules: {
        schoolName: [
            { required: true, message: '请输入学校名称', trigger: 'blur' },
          ],
        type: [{ required: true, message: '请选择专业类型', trigger: 'blur' }],
        majorName: [{ required: true, message: '请输入专业名称', trigger: 'blur' }],
        introduction: [{ required: true, message: '请输入专业介绍', trigger: 'blur' }],
      },
      form: {
        schoolName: '',
        majorName: '',
        introduction: '',
      },
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.oldForm));
  },
  methods: {
    typeChange() {
      this.$refs.form.validateField("type");
    },
  }
}
</script>

<style scoped lang="less">
  /deep/ .el-textarea__inner {
    max-height: 150px;
  }
</style>